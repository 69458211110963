<template>
  <el-dialog :title="name+' 预约情况'" class="info-div800" style="padding: 24px 24px 0 24px;text-align: left;margin-top: 15px">
    <div class="flex align-start">
        <div class="text-center">
          <div class="flex fontSize14gay">
            <div class="flex align-center"><div class="box-tip" style="background-color: #F2F5FF;"></div><div>可预约</div></div>
            <div class="flex align-center"><div class="box-tip" style="background-color: #F0F0F0;"></div><div>不可预约</div></div>
            <div class="flex align-center"><div class="box-tip" style="background-color: red;"></div><div>已预约</div></div>
          </div>
          <div class="flex marginTop10px">
            <div v-if="Info.length>0">
              <div class="title-appoin"><div>日期</div><div>时间段</div></div>
              <div v-for="(c,index) in Info[0].curriculumList" :key="index" class="fontSize12gay66 Time-oclp">
                <div>{{c.start}}</div><div>{{c.end}}</div>
              </div>
            </div>
            <el-scrollbar>
              <div class="flex" style="width: 620px;">
                <div v-for="(cda,index) in Info" :key="index" style="margin-left: 8px">
                  <div class="title-appoin" style="padding-top: 10px">{{cda.date}}</div>
                  <div v-for="(cour,index1) in cda.curriculumList" :key="index1" class="Course-oclp" :class="cour.isAppoint?'course-used-allow':(cour.user?'course-used-check':'course-used-no')">
                    <div v-if="cour.user">
                      <div>{{cour.name}}</div>
                      <div style="font-size: 10px;color: #EBEBEB !important;">{{cour.type==1?'教师':'学生'}}:{{cour.user?cour.user.name:''}}</div>
                    </div>
                    <div v-else style="padding-top: 12px">{{cour.name}}</div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
  </el-dialog>
</template>
<script>
import {getRoomYuYueInfo} from "@/api/api";
export default {
  name: "index",
  props:['Row'],
  data(){
    return{
      token:localStorage.getItem('token'),
      Info:[],
      name:''
    }
  },
  mounted() {
  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    getInfo(row){//清空表单
      if(row){
        this.name=row.name;
        getRoomYuYueInfo(row.id).then((res)=>{
          this.Info=res.data;
        })
      }
    },
    rpoo(){

    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item{
  display: flex;align-items: center;
  .el-form-item__label{width: 800px !important;color: red;padding: 0 15px 0 0;}
  .room-select-tl{width: 300px;}
  .room-select-al{width: 600px;}
  .room-input-tl{width: 600px;height: 40px}
  .room-input-cl{width: 300px;height: 40px}
  .room-input-qb{width: 100px;height: 40px}
  .el-form-item--large .el-form-item__content{line-height: 16px !important;}
}
.title-appoin{color:#333333;font-size: 13px;width: 90px;height: 52px;}
.Time-oclp{padding:6px 0px;height: 32px;width: 90px;margin-bottom: 8px;font-size: 13px}
.Course-oclp{height: 44px;width: 90px;font-size: 13px;margin-bottom: 8px;vertical-align: middle}
.course-used-allow{background: #EBF0F8;color: #3769B9;cursor: pointer;}
.course-used-no{background: #F0F0F0;color: #666666;cursor: not-allowed}
.course-used-check{background: red !important;color: #ffffff !important;cursor: default;}
.box-tip{height: 12px;width: 12px;border-radius: 2px;margin: 0 10px 0 20px}
.my-form-title{width: 108px;text-align: right;color: #606266;font-size: 14px;padding-right: 12px}
.form-table-jl{
  width: 600px;
  th{font-size: 14px;}
  td{font-size: 13px;color: #999999}
  input{height: 26px;width: 50px;border: 1px solid #cccccc;outline: none}
}
</style>